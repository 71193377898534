<template>
 <div class="content"><h1>{{message}}</h1></div>
</template>

<script>
export default {
  name: 'Page404',
  computed: {
    message() {
      return '找不到页面'
    }
  }
}
</script>

<style scoped>

</style>
